import {
  getCurrentInstance,
} from 'vue'
import { get } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDeleteItem({
  runAfterDeletion = async () => {},
  deleteHandler,
  getQuestionTextBeforeDeletion = item => `Сущьность с ID = ${item.id} будет удалена`,
  getTextAfterDeletion = item => `Сущьность с ID = ${item.id} была удалена`,
}) {
  const instance = getCurrentInstance().proxy

  const deleteItem = async item => {
    const resButton = await instance.$swal({
      title: 'Вы уверены?',
      html: getQuestionTextBeforeDeletion(item),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Да, удалить!',
      cancelButtonText: 'Отмена',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    })

    if (resButton.value) {
      try {
        await deleteHandler(item)
        runAfterDeletion()
        instance.$toast({
          component: ToastificationContent,
          props: {
            title: 'Удалено!',
            icon: 'BellIcon',
            text: getTextAfterDeletion(item),
            variant: 'success',
          },
        })
      } catch (e) {
        instance.$swal({
          icon: 'error',
          title: 'Ошибка!',
          text: get(e, 'response.data.error', 'Что то пошло не так, попробуйте позже или обратитесь в техническую поддержку.'),
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      }
    }
  }

  return {
    deleteItem,
  }
}
