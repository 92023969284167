var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary"}},[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('h5',[_vm._v("Поля")])]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.isAddFieldSidebarOpen = true}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Создать")])])],1)])],1)],1),_c('b-table',{ref:"refCompanyListTable",staticClass:"position-relative list-view-table",attrs:{"items":_vm.items,"responsive":"","fields":[
        { key: 'name', label: 'Название' },
        { key: 'key', label: 'Ключ' },
        { key: 'description', label: 'Описание' },
        { key: 'type', label: 'Тип' },
        { key: 'sort_order', label: 'Сортировка' },
        { key: 'actions', label: 'Действия' },
      ],"primary-key":"key","sort-by":'sort_order',"show-empty":"","empty-text":"Ничего не найдено!","sort-desc":false},scopedSlots:_vm._u([{key:"cell(type)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.get(_vm.fieldTypes.find((item) => item.value === data.item.type), 'name'))+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[[_c('b-dropdown-item',{on:{"click":function($event){return _vm.showEditItemSidebar(data.item)}}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Изменить")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(data.item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Удалить")])],1)]],2)]}}])})],1),_c('add-field-sidebar',{attrs:{"is-active":_vm.isAddFieldSidebarOpen},on:{"update:isActive":function($event){_vm.isAddFieldSidebarOpen=$event},"update:is-active":function($event){_vm.isAddFieldSidebarOpen=$event},"add":_vm.addField}}),_c('edit-field-sidebar',{attrs:{"is-active":_vm.isEditFieldSidebarOpen,"field":_vm.editableItem},on:{"update:isActive":function($event){_vm.isEditFieldSidebarOpen=$event},"update:is-active":function($event){_vm.isEditFieldSidebarOpen=$event},"update:field":function($event){_vm.editableItem=$event},"update":_vm.updateField}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }