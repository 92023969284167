import { ref, watch } from 'vue'

export default (key, defaultValue) => {
  const variable = ref(JSON.parse(localStorage.getItem(key) || (defaultValue ? JSON.stringify(defaultValue) : null)))

  watch(
    variable,
    to => {
      localStorage.setItem(key, JSON.stringify(to))
    },
    { deep: true },
  )

  return variable
}
