const types = [
  {
    name: 'Строка',
    value: 'string',
  },
  {
    name: 'Текст',
    value: 'text',
  },
]
export default types
